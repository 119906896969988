import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import '../components/joinrenew.css'
import Swal from "sweetalert2"
import { AXIOSLINK } from "../utils/constants"
const axios = require('axios')
import {
  MDBBtn,
  MDBIcon,
} from 'mdbreact'


const joinRenew = ({
  data: {
    allStrapiJoinRenewPages: { nodes: joinpage },
  },
}) => 
{    
    let arr = joinpage[0].JoinRenewPageDynamciZone;
    let modifiedArr = arr.map(function(element){
      console.log(element);
      if(element.TitleName !=null && element.Description ==null){
      return <h1 style = {{paddingBottom: "30px", textAlign: element.TitleAlignment, color:element.TitleFontColor,fontFamily:element.TitleFontFamily,fontSize:element.TitleFontSize,fontWeight:element.TitleFontWeight}}>{element.TitleName} </h1>
      }
      else if(element.Subtitle_Name != null && element.Description ==null && element.TitleName == null){
        return <h3 style = {{textAlign: element.SubtitleAlignment, color:element.SubtitleFontColor,fontFamily:element.SubtitleFontFamily,fontSize:element.SubtitleFontSize,fontWeight:element.SubtitleFontWeight}}>{element.Subtitle_Name}</h3>
      }
      else if(element.Description !=null){
         return <p style={{paddingBottom: "5px"}} ><ReactMarkdown className="descriptionProperties" source={element.Description} /></p>
     } 
    });
      console.log(modifiedArr);   

      function options() {
                /* inputOptions can be an object or Promise */
        const inputOptions = new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              'ReturningMember': 'Returning Member',
              'NewMember': 'New Member',
            })
          }, 1000)
        })

        const { value: selectedValue } = Swal.fire({
          title: 'Which Are You ?',
          input: 'radio',
          inputOptions: inputOptions,
          confirmButtonColor: '#00c851',
          inputValidator: value => {
            if (!value) {
              return 'You need to choose something!'
            } else if (value == 'ReturningMember') {
              return ReturningMember()
            } else {
              sessionStorage.setItem('NewMembership', 'true')
              window.location.pathname = '/membershipapplication'
            }
          },
        })
      }


      function ReturningMember(){
          const { value: email } = Swal.fire({
            title: 'Enter your Email ID',
            input: 'email',
            inputLabel: 'Your ISRID Email address',
            confirmButtonColor: '#00c851',
            inputValidator: value => {
              if (!value) {
                return 'You need to write something!'
              } else {
                axios
                  .get(AXIOSLINK + 'users', {
                    params: { email: value },
                  })
                  .then(function(response) {
                    console.log(response)
                    const UserData = {
                      id: response.data[0].id,
                      firstName: response.data[0].FirstName,
                      lastName: response.data[0].LastName,
                      email: response.data[0].email,
                      address: response.data[0].Address,
                      city: response.data[0].City,
                      licenseNumber: response.data[0].IOWALicenseNumber,
                      interpreterCredentials:
                      response.data[0].InterepeterCredentials,
                      state: response.data[0].State,
                      phoneNumber: response.data[0].UserPhoneNumber,
                      zipcode: response.data[0].Zipcode,
                      opt:response.data[0].Opt,
                      expirydate : response.data[0].NextRenewalDate,
                    }
                    if (value == response.data[0].email) {
                      console.log('success')
                      sessionStorage.setItem(
                        'PersonValue',
                        JSON.stringify(UserData)
                      )
                      sessionStorage.setItem('ReturningMembership', 'true')
                      sessionStorage.setItem(
                        'ValidEmail',
                        response.data[0].email
                      )
                      window.location.pathname = '/membershipapplication'
                    }
                  })
                  .catch(err => {
                    console.log(err)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Invalid Email!',
                      confirmButtonColor: '#00c851',
                    })
                  })
              }
            },
          })
      }

      

    return (
      <Layout>
        <div
          className="card joinPageCard"
          style={{ backgroundColor: joinpage[0].PageBackgroundColor }}
        >
          <div className="container">
            <div>
              {modifiedArr}
              {/* <Img fixed={joinpage[0].JoinRenewPageDynamciZone[5].ImageOrVideo.childImageSharp.fixed}/> */}
            </div>
            {/* <button type="button" class="button_green" onClick={options}>PROCEED TO NEXT STEP</button> */}
            {/* <MDBBtn color="success"  outline type="submit" onClick={options}>
             Proceed to Next Step 
            </MDBBtn> */}
            <button type="submit" class="btn btn-success joinbtn" onClick={options}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"27%"}}>Proceed to Next Step </button>
          </div>
        </div>
      </Layout>
    )
}
export const query = graphql`
  {
    allStrapiJoinRenewPages {
      nodes {
        PageBackgroundColor
        JoinRenewPageDynamciZone {
          Description
          TitleName
          TitleAlignment
          TitleFontColor
          TitleFontFamily
          TitleFontSize
          TitleFontWeight
          SubtitleAlignment
          SubtitleFontColor
          SubtitleFontFamily
          SubtitleFontSize
          SubtitleFontWeight
          Subtitle_Name
        }
      }
    }
  }
`
export default joinRenew


